import React from "react";
import { FaDiscord, FaTelegramPlane, FaTwitter } from "react-icons/fa";
import { IconContext } from "react-icons";

export default function navbarItems() {
  return (
    <div>
      <a href="#" className="border-b border-white  py-5 text-lg">
        Coming Soon
      </a>
      {/* <div className="my-20 flex gap-10">
        <IconContext.Provider value={{ className: "text-2xl" }}>
          <a href="https://t.me/SenSeiFi">
            <FaTelegramPlane />
          </a>
        </IconContext.Provider>
        <IconContext.Provider value={{ className: "text-2xl" }}>
          <a href="https://twitter.com/SenSei_DeFi">
            <FaTwitter />
          </a>
        </IconContext.Provider>
        <IconContext.Provider value={{ className: "text-2xl" }}>
          <a href="https://discord.gg/b6h3paCYy4">
            <FaDiscord />
          </a>
        </IconContext.Provider>
      </div> */}
    </div>
  );
}
