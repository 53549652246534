import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import "./Timeline.css";

const VTEProps = {
  className: "vertical-timeline-element--work",
  contentStyle: {
    color: "#FFF",
    boxShadow: "none",
    textAlign: "left",
    padding: "1rem",
  },
  contentArrowStyle: {
    display: "none",
  },

  dateClassName: "milestone",
  iconStyle: {
    background: "#15112c",
    color: "#FFF",
    fontSize: "1.5rem",
    fontWeight: 400,
    border: "1px solid #38e19e",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    boxShadow: "0 0 20px #38e19e50, inset 0 0 10px #38e19e50",
  },
  position: "right",
};

export default function Timeline() {
  return (
    <VerticalTimeline layout="2-columns">
      <VerticalTimelineElement {...VTEProps} date="Testnet" icon={<p>1.0</p>}>
        {/* <h3 className="vertical-timeline-element-title">Creative Director</h3>
        <h4 className="vertical-timeline-element-subtitle">Miami, FL</h4> */}

        <p>
          • Stake and Earn
          <br />
          <br />
          • Stake & Launch
          <br />
          <br />
          • Bonded Curve Launches
          <br />
          <br />• Straight auction launch
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement {...VTEProps} date="Mainnet" icon={<p>2.0</p>}>
        <p>
          • Permissionless Launchpad
          <br />
          <br />
          • Direct AMM LP integration to drive TVL
          <br />
          <br />
          • Bridge Integration to drive omnichain
          <br />
          <br />• Auto Compounding Staking Rewards
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement {...VTEProps} date="Onwards!" icon={<p>3.0</p>}>
        <p>
          • Expanded multichain launches
          <br />
          <br />• Partnering With Cross Chain AMM to allow one click
          multichainon
          <br />
          <br />
          • Multichain Aggregator
          <br />
          <br />• DEX Router & Aggregator Integration
        </p>
      </VerticalTimelineElement>
    </VerticalTimeline>
  );
}
