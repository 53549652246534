import "./App.css";
import "./input.css";
import React, { useState, useEffect } from "react";
import WhitelistForm from "./components/WhitelistForm";
import mockup from "./assets/mockup.png";
import { FaDiscord, FaTelegramPlane, FaTwitter } from "react-icons/fa";
import coinpile from "./assets/memetoken-coinpile.png";
import memeCoinTucan from "./assets/memecoin-tucan.jpeg";
// partners
// import braindex from "./assets/partners/Briandex Logo.png";
// import falconx from "./assets/partners/FalconX Logo.png";
// import mexc from "./assets/partners/MEXC Logo.png";
// import sparrowswap from "./assets/partners/Sparrowswap.png";
// import wormhole from "./assets/partners/Wormhole.png";
// import yieldly from "./assets/partners/Yieldly logo.png";

import beanie from "./assets/team/beanie.jpg";
import seijas from "./assets/team/seijas.jpg";
import porky from "./assets/team/porky.jpg";
import jelly from "./assets/team/jelly.jpg";

import { IconContext } from "react-icons";
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import Timeline from "./components/Timeline";

import { ReactComponent as Tucan } from "./assets/tucan-bird.svg";
import tucan from "./assets/tucan-bird-neon.png";

const team = [
  {
    name: "Seijas san",
    image: seijas,
    info: "Data-driven technologist with over 5 years blockchain and start up experience. Passionate about innovation.",
  },
  {
    name: "Beanie san",
    image: beanie,
    info: "Entrepreneur with a strong history of building & supporting the growth of some of the largest distributed ledger & DeFi companies.",
  },
  {
    name: "Porky san",
    image: porky,
    info: "Blockchain developer with over 10 years design experience. Focussed on delivering cross-chain websites and webapps.",
  },
];

export default function Home() {
  const [showWhitelist, setShowWhitelist] = useState(true);

  const [position, setPosition] = useState({ x: 0, y: 0 });

  return (
    <>
      <section
        className="App via-violent-dark relative h-auto w-full 
  overflow-hidden bg-violet-dark bg-gradient-to-t from-transparent to-[#372d8e] text-center"
      >
        <div className="flex justify-center ">
          <img
            src={coinpile}
            className="absolute bottom-0 h-auto w-full max-w-4xl "
          />
        </div>
        <div
          className="absolute -right-[50%] bottom-0 h-2/3 w-2/3 bg-[#fd4e9d] bg-opacity-70 blur-[10rem]"
          style={{
            filter: "blur(100px)",
            borderRadius: "70%",
            boxShadow: "0 0 100px #fd4e9d",
            //transform: `translate(${position.x * 0.1}px, ${position.y * 0.1}px)`,
          }}
        />
        <div
          className="absolute -left-[50%] top-0 h-2/3 w-2/3 bg-[#fd4e9d] bg-opacity-70 blur-[10rem]"
          style={{
            filter: "blur(100px)",
            borderRadius: "70%",
            boxShadow: "0 0 100px #fd4e9d",
            // transform: `translate(${position.x * -0.1}px, ${
            //   position.y * -0.1
            // }px)`,
          }}
        />
        <div className="translate-y-1/4">
          <div className="p-5 md:mt-20">
            <h1 className="glow-text text-5xl md:text-[4rem]">
              The most intuitive meme launchpad
              <br />
              <span className="font-bold"></span>
            </h1>
            <h2 className="glow-text mt-5 text-4xl font-bold md:text-[4rem]">
              for Canto
            </h2>
            <h3 className="mx-auto mt-10 mb-5 text-xl font-light md:w-4/5 md:text-3xl">
              Capture the market for traders!
            </h3>
            {/* <p className="mx-auto my-7 max-w-xl text-2xl">
          Take complete ownership of your own Web3 tokens. Create, deposit &
          withdraw incredible savings returns + bonus rewards anytime.
        </p> */}
          </div>

          <div className="mx-auto mb-28 flex items-center justify-center">
            <div className="relative  my-2 max-w-sm  rounded border-2 border-transparent bg-[#fdec5e] px-5 py-2 text-2xl text-violet-dark shadow-[#35e835] transition-all hover:cursor-pointer hover:border-[#fdec5e] hover:bg-[#fdec5e]/50  hover:text-violet-dark md:w-80">
              <a
                href="#"
                className=" flex items-center justify-around hover:text-white"
              >
                Coming Soon
              </a>
              <i />
            </div>
            <div className="relative -mt-20 -ml-7">
              <div
                className=" absolute z-10 h-24 w-24 bg-cover bg-center "
                style={{ backgroundImage: `url(${tucan})` }}
              />
              <div
                className="absolute z-[9] h-24 w-24 bg-cover bg-center blur-md "
                style={{ backgroundImage: `url(${tucan})` }}
              />
            </div>
          </div>
        </div>
      </section>

      <section
        className=" 
  mx-auto flex w-full max-w-6xl flex-col text-left md:flex-row md:text-center"
      >
        <div className="m-10 max-w-xl md:my-16 md:mx-10">
          <h2 className="glow-text-border text-left text-6xl font-medium ">
            The <span className="">Road</span> Ahead
          </h2>
        </div>
        <div className="m-10 md:w-full">
          <Timeline />
        </div>
      </section>
      <div className="relative">
        <div
          className=" absolute bottom-0 z-10 h-20 w-full
       bg-gradient-to-t from-[#38e19e] md:h-24"
        />
      </div>
      <section
        className="mx-auto 
  flex w-full flex-col justify-center bg-violet-dark md:flex-row md:text-center"
      >
        <img
          src={memeCoinTucan}
          className="min-h-96 w-auto max-w-full md:max-h-96"
        />
      </section>

      <footer className="w-full ">
        {/* <img src={mockup} className="h-auto w-full" /> */}
        <div className="bg-violet-dark py-5 text-center font-light">
          <div className="mx-5 flex flex-col justify-between md:flex-row">
            <p>Copyright &copy; Tucan Fi, 2023.</p>
            <div className="mt-2 flex flex-col md:mt-0 md:flex-row  md:gap-5">
              {/* <a
                href="https://branding.senseifi.io/logos.zip"
                style={{ textDecoration: "underline" }}
              >
                Download Branding
              </a> */}
              {/* <a target="_blank" href="https://app.senseifi.io/tnc">
                Terms & Condition
              </a> */}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
